import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import fi from "./fi.json";
import eng from "./eng.json";

i18n.use(initReactI18next).init({
  lng: "eng",
  fallbackLng: "eng",
  debug: true,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },

  resources: {
    fi: {
      translation: fi,
    },
    eng: {
      translation: eng,
    },
  },
});

export default i18n;
