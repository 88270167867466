import React, { Component, ReactNode } from "react";

import { Honeybadger } from "@honeybadger-io/react";

import PropTypes from "prop-types";
import Button from "./Button";

const config = {
  apiKey: "hbp_nOa8iBgITileHvvdhcIBLuQOcmz4kF1UtUhL",
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || "development",
};

const honeybadger = Honeybadger.configure(config);

interface HoneybadgerErrorBoundaryProps {
  honeybadger?: typeof Honeybadger | any | undefined;
  showUserFeedbackFormOnError?: boolean;
  ErrorComponent?: ReactNode;
  children: any;
  className?: string;
}

interface DefaultErrorComponentProps {
  error?: Error | null;
  info?: React.ErrorInfo | null;
}

interface HoneybadgerErrorBoundaryState extends DefaultErrorComponentProps {
  errorOccurred: boolean;
  error?: any;
  info?: any;
}

const DefaultErrorComponent = (props: DefaultErrorComponentProps) => {
  return (
    <>
      <h1 className="text-center margin-2rem margin-top"></h1>

      {/*centered button*/}

      <Button
        style={{
          width: "170px",
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
        }}
        onClick={() => {
          if (window) {
            window.location.reload();
          }
        }}>
        Tapahtui virhe! {props.error && props.error.message} Kokeile uudestaan
      </Button>
    </>
  );
};

export default class HoneybadgerErrorBoundary extends Component<
  HoneybadgerErrorBoundaryProps,
  HoneybadgerErrorBoundaryState
> {
  static propTypes = {
    honeybadger: PropTypes.object,
    showUserFeedbackFormOnError: PropTypes.bool,
    children: PropTypes.element,
    ErrorComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  };

  static defaultProps = {
    showUserFeedbackFormOnError: false,
  };

  constructor(props: HoneybadgerErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      info: null,
      errorOccurred: false,
    };
    /*
    this.props.honeybadger.afterNotify((error, _notice) => {
      if (!error && this.props.showUserFeedbackFormOnError) {
        //this.props.honeybadger.showUserFeedbackForm();
      }
    });
    */
  }

  public static getDerivedStateFromError(
    error: Error
  ): HoneybadgerErrorBoundaryState {
    return { error: error, errorOccurred: true, info: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ errorOccurred: true, error: error, info: errorInfo });
    honeybadger.notify(error, { context: errorInfo as never });
  }

  private getErrorComponent(): ReactNode {
    return this.props.ErrorComponent ? (
      React.createElement(this.props.ErrorComponent as never, this.state)
    ) : (
      <DefaultErrorComponent {...this.state} />
    );
  }

  render() {
    return (
      <>
        {this.state.errorOccurred
          ? this.getErrorComponent()
          : this.props.children}
      </>
    );
  }
}
