//import "../styles/globals.css";
import React from "react";
import Head from "next/head";
import { FC, Suspense, useEffect } from "react";
import i18n from "../localization/i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ErrorBoundary from "../components/ErrorBoundary";
import "../styles/globals.css";
import "../styles/reset.css";
import "../styles/react-calendar.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      useErrorBoundary: true,
    },
  },
});

export default function App(props: any) {
  const { Component, pageProps } = props; //AppProps;

  const getLayout: any = Component.getLayout || ((page: FC) => page);

  useEffect(() => {
    i18n.changeLanguage("eng");
  }, []);

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,900;1,400;1,500;1,600&family=Space+Grotesk:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />

        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<h4>Ladataan...</h4>}>
            {getLayout(<Component {...pageProps} />)}
          </Suspense>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
}
