import React from "react";
import styles from "./Button.module.css";

type ButtonProps = {
  color?: "primary" | "secondary";
  variant?: "contained" | "outlined";
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  style?: object;
};

const Button = ({
  color = "primary",
  variant = "contained",
  disabled = false,
  onClick,
  children,
  className,
  style,
  type,
}: ButtonProps) => {
  const buttonClassNames = `${className} ${styles.button} ${styles[color]} ${
    variant === "outlined" ? styles.outlined : ""
  }`;

  return (
    <button
      style={style}
      className={buttonClassNames}
      type={type}
      disabled={disabled}
      onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
